import { useMemo } from 'react'
import { Card, CardContent, Typography } from '@mui/material'

import { useETHPrice, useIronballETHLocked } from 'app/hooks/graph/useGraph'
import {
  useBackUpAssetManagement,
  useETHBalanceInHONO,
  useETHBalanceInHONOV2,
  useETHLinkTVL,
  useHONOUsdcTVL
} from 'app/hooks/useBackPrice'
import { formatCurrency } from 'app/utils/helper'

export const TVL = () => {
  const { data: ethPrice = 0 } = useETHPrice()

  const { data: ethBalance = 0 } = useETHBalanceInHONO()
  const { data: ethBalanceV2 = 0 } = useETHBalanceInHONOV2()
  const { data: honoUsdcTVL = 0 } = useHONOUsdcTVL()
  const { data: ethLinkTVL = 0 } = useETHLinkTVL()
  const { data: backupAsset = 0 } = useBackUpAssetManagement()
  const { data: ironballETHLocked = 0 } = useIronballETHLocked()

  const assetManagement = ethLinkTVL + backupAsset

  const tvl = useMemo(() => {
    return (
      +ethBalance * +ethPrice +
      +ethBalanceV2 * +ethPrice +
      +honoUsdcTVL +
      +assetManagement +
      +ironballETHLocked * +ethPrice
    )
  }, [ethBalance, ethBalanceV2, ethPrice, honoUsdcTVL, assetManagement, ironballETHLocked])

  return (
    <Card>
      <CardContent
        sx={{ pt: '12px', pb: '8px', textAlign: 'center', color: 'common.white', bgcolor: 'rgb(55, 71, 79)' }}
      >
        <Typography variant={'h1'} fontSize={'20px'} fontWeight={500} color={'inherit'}>
          Total Value Locked
        </Typography>
      </CardContent>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant={'h2'} fontSize={'20px'} fontWeight={500}>
          {tvl && `~${formatCurrency(tvl)}`}
        </Typography>

        <Typography variant={'h3'} fontSize={'15px'} mb={1}>
          {ethBalance && ethPrice && `HONO V1 Backing: ~${formatCurrency(+ethBalance * +ethPrice)}`}
        </Typography>
        <Typography variant={'h3'} fontSize={'15px'} mb={1}>
          {ethBalanceV2 && ethPrice && `HONO V2 Backing: ~${formatCurrency(+ethBalanceV2 * +ethPrice)}`}
        </Typography>
        <Typography variant={'h3'} fontSize={'15px'} mb={1}>
          {honoUsdcTVL && `HONO V2-USDC LP: ~${formatCurrency(honoUsdcTVL)}`}
        </Typography>
        <Typography variant={'h3'} fontSize={'15px'} mb={1}>
          {assetManagement && `ASSETS UNDER MANAGEMENT: ~${formatCurrency(assetManagement)}`}
        </Typography>
        <Typography variant={'h3'} fontSize={'15px'} mb={1}>
          {ironballETHLocked && `IRONBALL ASSETS MANAGED: ~${formatCurrency(+ironballETHLocked * +ethPrice)}`}
        </Typography>

        <Typography variant={'h2'} fontSize={'14px'} mt={7}>
          TVL is the amount of backing assets + total Assets Under Management
        </Typography>
      </CardContent>
    </Card>
  )
}
